import { pagesInfo } from '~/config/pagesInfo'
import type { PagesInfo, PageInfo } from '~/config/pagesInfo'
import type { RoleKey } from '~/types/roleKey'
const $route = useRoute()

export default defineNuxtRouteMiddleware(async to => {
  const { $client } = useNuxtApp()
  const $store = useStore()

  /*
   * NOTE: v4/auth/me ログインチェック
   * 200: ログイン済み
   * 200以外: APIエラーのため強制ログアウト
   */
  try {
    const resIp = await $client.v3.ip.$get()
    const resMe = await $client.v4.auth.me.get({ query: { ip: resIp.ip } })
    $store.setUser(resMe.body)
    $store.setCurrentIp(resIp.ip)
    if (resMe.status !== 200) {
      return navigateTo('/auth/login')
    }
  } catch {
    return navigateTo('/auth/login')
  }

  // メール配信機能（テスト用）
  // 1年後アンケートPDFダウンロード画面
  if (
    $route.path.includes('/company/test/') ||
    $route.path.includes('/company/member/anniversary/pdf')
  ) {
    return
  }

  // ユーザーのロールがページに対応するロールの一つでもあればアクセスを許可
  const allPages = pagesInfo.reduce((pages: PagesInfo, page: PageInfo) => {
    if (page.href) {
      pages.push(page)
    }

    if (page.child) {
      return pages.concat(page.child)
    }

    return pages
  }, [])

  const currentPageInfo: PageInfo | undefined = allPages.find(page => {
    if (page.href) {
      const pathsToCheck = [page.href].concat(page.alias || [])
      return pathsToCheck.some(href => matchPath(href, to.path))
    }
    return false
  })

  if (!currentPageInfo) {
    return navigateTo('/')
  }

  const user = $store.getUser
  const currentUserRole = user?.roles ? user?.roles[0].value : null
  if (!currentPageInfo.roles?.includes(currentUserRole as RoleKey)) {
    return navigateTo('/')
  }

  if (
    $store.getUser?.has_security_log &&
    $store.getUser?.current_ip_is_valid &&
    $store.getUser?.currentIp
  ) {
    const { $i18n } = useNuxtApp()
    const { tm, rt } = $i18n.global
    interface RouteJson {
      [key: string]: { uri: string; task: string }
    }
    const searchUri = to.path.replace(/[0-9]*/g, '')
    const { $client } = useNuxtApp()
    const routes = ((): { uri: string; task: string } | null => {
      const routeJson = tm('routes') as RouteJson
      const route = routeJson[searchUri]
      if (route) {
        return { uri: rt(route.uri), task: rt(route.task) }
      } else {
        return null
      }
    })()
    if (routes) {
      try {
        await $client.v3.settings.security.register.post({
          body: {
            routes,
            ip: $store.getUser.currentIp,
          },
        })
      } catch {
        navigateTo('/auth/login')
      }
    }
  }

  // 「unread」「count」に関しては、refresh tokenの多重実行を防ぐため、意図的にPromise.allを実施していません。
  if ($store.getDepartments === null) {
    const departmentsData = await $client.v4.common.departments.search.$get()
    $store.setDepartments(departmentsData.result.departments)
  }
  const unreadData = await $client.v3.members.unread.$get()
  $store.setMessageUnread(unreadData.count)
  const countData = await $client.v3.members.anniversaries.count.$get()
  $store.setNewAnniversaryAnswer(countData)
})

// 動的なパスを含むルートを正確に比較するための関数
function matchPath(pagePath: string, currentPath: string): boolean {
  // 末尾のスラッシュを取り除く
  pagePath = pagePath?.replace(/\/$/, '')
  currentPath = currentPath?.replace(/\/$/, '')
  const routeSegments = currentPath?.split('/')
  const pageSegments = pagePath?.split('/')
  if (routeSegments?.length !== pageSegments?.length) {
    return false
  }
  for (let i = 0; i < routeSegments.length; i++) {
    if (
      routeSegments[i] !== pageSegments[i] &&
      !pageSegments[i].startsWith(':')
    ) {
      return false
    }
  }
  return true
}
